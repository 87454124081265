@media all and (min-width: 100px) {
  .Login {
    padding: 30px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }


}

.col_half {
	width:50%;
	float:left;
  padding: 2px;
}

.btn-rounded {
  background-color: rgb(233, 30, 99) !important;
  border-color: rgb(233, 30, 99) !important;
}

.btn-text {
  background-color: rgb(233, 30, 99) !important;
  border-color: rgb(233, 30, 99) !important;
  color: #fff !important;
}
