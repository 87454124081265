.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  z-index: 9999;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: slategrey;
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Menu */

.menu {
  width: 100%;
  display: block;
  padding: 4.5em 4.5em 0;
}
.menu ul {
  position: relative;
  top: 0px;
  font-size: 1.15em;
  padding: 0px;
}
.menu li {
  list-style: outside none none;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer;
}
.menu li:hover {
  color: #ff0000;
}
